@import '../../styles/colors';

.container {
	width: 100%;
	gap: 8px 0;
	display: flex;
	flex-wrap: wrap-reverse;
	justify-content: space-between;
	align-items: center;

	.infos {
		display: flex;
		flex-wrap: wrap-reverse;
		align-items: flex-start;
		color: $blue;
		gap: 8px 25px;

		.watchIcon {
			max-height: 30px;
			max-width: 30px;
			height: auto;
			width: auto;
		}

		.watchId {
			font-size: 33px;
			font-weight: 500;
			max-width: 350px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.watchModel {
			padding-bottom: 5px;
			font-size: 12px;
			max-width: 350px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.dataButtons {
		display: flex;
		gap: 25px;

		img {
			margin-right: 10px;
		}

		.importButton {
			display: flex;
			align-items: center;
			font-size: 12px;
			background-color: $blue;
			color: $white;
			padding: 10px 20px;
			border-radius: 10px;
		}

		.exportButton {
			display: flex;
			align-items: center;
			border: solid 1px $blue;
			font-size: 12px;
			background-color: $white;
			color: $blue;
			padding: 10px 20px;
			border-radius: 10px;
		}
	}
}
.importDataLoading {
	color: $gray;
	font-size: 0.9rem;
	display: flex;
	align-items: flex-end;
	padding-bottom: 4px;
	gap: 8px;
	margin-right: -8px;
}

.importSuccessMsgContainer {
	position: absolute;
	width: 100%;
	top: 24px;
	left: 0;
	padding: 0 20px;
}
.importSuccessMsg {
	margin: auto;
	padding: 12px;
	background-color: $green;
	color: white;
	text-align: center;
	max-width: 500px;
	border-radius: 5px;
}
