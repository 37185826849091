@import '../../styles/colors';

.container {
	height: 100%;
	width: 100%;
	min-height: 90vh;
	.content {
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 30px 0;

			.deconnectionBtn {
				color: $blue;
				text-decoration: underline;
				font-size: 12px;
			}
		}
		padding: 0 20px;
		margin: auto;
		height: auto;
		max-width: 1400px;
		@media (min-width: 600px) {
			padding: 0 40px;
		}
	}
}
