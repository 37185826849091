@import '../../styles/colors';

.picker {
	max-height: 20px;
	max-width: 450px;
	z-index: 999;

	.pickerButton {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		gap: 8px;

		.dateDisplayContainer {
			.dateDisplay {
				display: flex;
				justify-content: flex-end;
			}
		}

		img {
			margin-top: 4px;
		}

		.openImage {
			transform: scaleY(-1);
		}
	}

	.pickerContainer {
		background-color: $white;
		border: 1px solid $blue;
		border-radius: 16px;
		padding: 24px;
		margin: 16px;

		.compareButton {
			display: flex;
			align-items: center;
			gap: 10px;
			margin: 20px 0;
		}

		.rangeCalendar {
			border: none;
			margin: none;
			margin-top: 20px;
		}
	}

	.dataButtons {
		margin-top: 15px;
		display: flex;
		justify-content: space-evenly;

		.applyButton {
			font-size: 1rem;
			background-color: $blue;
			color: $white;
			padding: 10px 30px;
			border-radius: 10px;

			&:disabled {
				background-color: $light-gray;
			}
		}

		.modBtn {
			color: $blue;
			text-decoration: underline;
		}
	}
}

.pickerBackground {
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.hint {
	position: absolute;
	top: 2px;
	border-radius: 8px;
	height: 6px;
	width: 6px;
	left: calc(50% - 3px);
	background-color: $blue;
}
