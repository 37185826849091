@import "../../styles/colors";

.headline {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
    padding: 0 60px;
    font-weight: 500;
    color: $disabled-gray;
    @media (min-width: 600px) {
        font-size: 2rem;
    }
}
