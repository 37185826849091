@import "../../styles/colors";

.card {
    box-shadow: 10px 3px 50px $shadow-black;
    border-radius: 16px;
    min-height: 100px;
    min-width: 200px;
    width: 100%;
    padding: 16px;

    @media (min-width: 700px) {
        padding: 40px;
    }
}
