@import "../../styles/colors";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 11px 0;
    width: 100%;
    height: 200px;
    border: 2px dashed $blue;
    background-color: $light-blue;
    border-radius: 10px;
}

.uploadIcon {
    height: 32px;
    width: 25px;
    margin-bottom: 20px;
}

.dropMsg {
    color: $blue;
}

.openExplorerText {
    color: $blue;
}

.openExplorerBtn {
    text-decoration: underline;
    cursor: pointer;
}

.fileInput {
    display: none;
}

.filesDisplay {
    margin: 20px 0;
}

.fileStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fileInfo {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 90%;
}

.fileName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $blue;
}

.errorMsg {
    color: $error;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.removeFile {
    color: white;
    cursor: pointer;
    background-color: $blue;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancelBtn {
    margin-left: 20px;
    color: $blue;
    text-decoration: underline;
}

.inputErrorMessage {
    background-color: $error;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
}

.errorMessage {
    background-color: $error;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin: 5px 0;
    text-align: center;
}
