@import '../../styles/colors';

.container {
  display: flex;
  justify-content: space-between;

  .dateWrapper {
    margin-top: 10px;
    border: 1px solid $blue;
    border-radius: 8px;
    padding: 5px 20px;
  }
}