@import './colors';

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-Medium.ttf');
}

* {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}
*::-webkit-scrollbar {
	display: none;
}

[type='date']::-webkit-inner-spin-button {
	display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
	display: none;
}

body {
	background-color: $white;
	margin: 0;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	border: none;
	background: none;
	cursor: pointer;
	font-family: Montserrat, medium;
	&:active {
		outline: none;
	}
}

h2 {
	color: $blue;
	font-size: 33px;
	font-weight: 500;
	font-family: 'Montserrat', medium;
}

h3 {
	color: $blue;
	font-weight: 500;
	font-size: 18px;
	font-family: 'Montserrat', medium;
}

h4 {
	color: $blue;
	font-weight: 400;
	font-size: 14px;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

input {
	border: none;
}
input:focus-visible {
	outline: none;
}
