@import "../../styles/colors";

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 20px 24px 20px;
    height: 88px;
    font-size: 12px;
    color: $blue;
    text-align: center;
}
