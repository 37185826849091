@import '../../styles/colors';

.modalLayout {
	position: fixed;
	z-index: 1000;
  display: flex;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	cursor: initial;

  .modalContent {
    background-color: #fefefe;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;

		.header {
			padding-bottom: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;

			.title {
				font-size: 1.5rem;
				color: $blue;
			}

			.close {
				color: $blue;
				font-size: 1.8rem;
			}

			.close:hover,
			.close:focus {
				color: #000;
				text-decoration: none;
				cursor: pointer;
			}
		}
	}
}
