@import '../../styles/colors';

.card {
	display: grid;
	grid-template-columns: 11ch 2fr 1fr 30px;
	grid-template-rows: 1fr;
	gap: 0 8px;
	align-items: center;
	color: $blue;
	border-radius: 10px;
	padding: 17px;
	margin: 10px;
	box-shadow: 3px 3px 10px 0px rgba(46, 69, 116, 0.18);
	cursor: pointer;
}

.optionBtn {
	height: 3px;
}

.popper {
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popperContent {
	display: none;
	position: absolute;
	left: -100px;
	top: -16px;
	width: 105px;
	box-shadow: 3px 3px 10px 0px rgba(46, 69, 116, 0.18);
	background-color: white;
}

.popperBtn {
	padding: 10px;
	display: block;
	cursor: pointer;
	color: $blue;
	width: 100%;
	text-align: left;
}
.popperBtn:hover {
	background-color: #ddd;
}
.popper:hover .popperContent {
	display: block;
}

.modalBody {
	display: block;
	input {
		margin: 5px 0;
		padding: 10px;
		font-size: 1rem;
		width: 100%;
		background-color: rgb(238, 238, 238);
		border-bottom: 1px solid rgb(172, 172, 172);
		color: black;
	}
}

.inputGroup {
	margin: 20px 0;
}

.watchInfo {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cancelBtn {
	margin-left: 20px;
	text-decoration: underline;
}

.error {
	color: $error;
}
