@import "../../styles/colors";

.container {
    margin-top: 25px;
    height: auto;
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    button {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
    }

    .calendar {
        padding: 15px;
        flex: 2;
    }

    .singleDay {
        position: absolute;
        top: 5px;
        right: 2.5px;
        background-color: orange;
        height: 6px;
        width: 6px;
        border-radius: 50%;
    }

    .bodyHeader {
        text-align: left;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        color: $blue;
    }

    .modBtn {
        color: $blue;
        text-decoration: underline;
    }

    .delBtn {
        color: $red;
    }

    .annotation {
        flex: 3;
        display: flex;
        flex-direction: column;
        min-width: 240px;
        @media (min-width: 500px) {
            min-width: 300px;
        }
    }
    .annotationTextArea {
        width: 100%;
        min-height: 200px;
        flex: 1;
        padding: 15px;
        border: none;
        background: #f4f4f4;
        resize: none;
    }

    .saveBtn {
        align-self: flex-start;
        font-size: 16px;
        background-color: $blue;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        margin-right: 20px;
    }

    .bodyFooter {
        margin-top: 10px;
    }
}
