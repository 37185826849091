@import '../../styles/colors';

.button {
	font-size: 16px;
	background-color: $blue;
	color: white;
	padding: 12px 30px;
	border-radius: 10px;
  max-width: 206px;

	&:hover {
		box-shadow: 0 6px 12px $shadow-black;
	}
	&:active {
		box-shadow: 0 2px 4px $shadow-black;
	}
	&:focus {
		outline: none;
	}
	&.disabled {
		cursor: not-allowed;
		background-color: $gray;
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
}
