#container {
	width: 20px;
	display: inline-block;
	height: 20px;
}

@keyframes animation {
	0% {
		stroke-dasharray: 1 98;
		stroke-dashoffset: -105;
	}
	50% {
		stroke-dasharray: 80 10;
		stroke-dashoffset: -160;
	}
	100% {
		stroke-dasharray: 1 98;
		stroke-dashoffset: -300;
	}
}

#spinner {
	transform-origin: center;
	animation-name: animation;
	animation-duration: 1.2s;
	animation-timing-function: cubic-bezier;
	animation-iteration-count: infinite;
	fill: transparent;
	stroke: #707070;
	stroke-width: 7px;
	stroke-linecap: round;
	filter: url(#shadow);
}
