$shadow-black: rgba(0, 0, 0, 0.16);
$blue: rgb(0, 39, 87);
$light-blue: rgb(244, 245, 255);
$white: #fcfdff;
$gray: #707070;
$light-gray: rgb(221, 216, 216);
$very-light-grey: rgba(239, 239, 239, 1);
$error: rgb(239, 48, 76);
$red: rgba(239, 92, 92, 1);
$disabled-gray: #ebebeb;
$green: #43a047;
