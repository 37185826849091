.card {
	display: flex;
	flex-direction: column;
	gap: 50px;
	position: relative;

	.container {
		display: flex;
		flex-direction: column;
		gap: 50px;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-height: 30px;
		}

		.graph {
			display: flex;
			flex-direction: column;
			gap: 20px;
			height: 25vh;
			height: auto;

			.canvas {
				height: 300px;
				position: relative;
			}

			.heatmapContainer {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				position: relative;
				flex-wrap: wrap;
				min-height: 300px;
				.heatmap {
					padding: 20px;
					display: block;
					height: 100%;
					min-width: 300px;
					flex: 1;
					.heatmapGraph {
						height: 250px;
					}
				}
			}
			.heatmapLegend {
				height: 30px;
				width: 50%;
			}

			.legend {
				display: flex;
				justify-content: flex-start;
				gap: 50px;
				margin: 0 20px;

				.legendText {
					display: flex;
					align-items: center;
					gap: 10px;
					font-size: 14px;

					.firstCircle {
						width: 10px;
						height: 10px;
						border-radius: 50px;
						background-color: rgb(0, 39, 87);
					}

					.secondCircle {
						width: 10px;
						height: 10px;
						border-radius: 50px;
						background-color: rgb(236, 105, 10);
					}

					.legendDate {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
